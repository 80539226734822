import React from "react";
import { ProFormDigitRange, ProFormSelect } from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum";
import CourseSearchSelect from "@/entities/course/ui/select";
import PoolParticipantRegistryNumberSelect from "@/entities/pool-participant/ui/select-registry-number";
import PoolSelect from "@/entities/pool/ui/select";
import statuses from "@/entities/pool-participant/lib/statuses";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import RolesSearchSelect from "@/entities/roles/select";
import { useTranslation } from "react-i18next";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import CourseSelectActiveArchivedStatus from "@/entities/course/ui/select-status";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";

const ConsolidatedFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="single"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <DateTimeRangePickerWithTimeControls
        label="Период"
        colProps={{ span: 8 }}
        name={["range", "starts_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        normalize={normalizeDateRange}
      />
      <RolesSearchSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="Роль"
        name={["member", "role_id"]}
      />
      <ProFormSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="Статус пользователя"
        name={["member", "status"]}
        valueEnum={statusValueEnum}
        fieldProps={{ tagRender }}
      />
      <OrgStructureTreeSelect
        label={t("Должность")}
        name={["member", "positions", "position_id"]}
        colProps={{ span: 8 }}
        labelWithPath
        searchUser
        fieldProps={{ multiple: true }}
      />
      <CourseSearchSelect
        mode="multiple"
        label="Курс"
        name={["pool", "course", "id"]}
        colProps={{ span: 8 }}
      />
      <CourseSelectActiveArchivedStatus
        mode="multiple"
        label="Статус курса"
        name={["pool", "course", "status"]}
        colProps={{ span: 8 }}
      />
      <PoolParticipantRegistryNumberSelect
        colProps={{ span: 8 }}
        mode="multiple"
        label="Номер в реестре"
        name={"registry_number"}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <ProFormSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="Статус в потоке"
        name="status"
        valueEnum={statuses}
        fieldProps={{ tagRender }}
      />
      <ProFormDigitRange
        label="Прогресс прохождения"
        colProps={{ span: 8 }}
        name={["range", "progress_percent"]}
        fieldProps={{
          max: 100,
          min: 0,
        }}
        separator="-"
        placeholder={["от", "до"]}
      />
      <DateTimeRangePickerWithTimeControls
        label="Фактическая дата начала"
        name={["range", "started_at"]}
        colProps={{ span: 8 }}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        normalize={normalizeDateRange}
      />
      <DateTimeRangePickerWithTimeControls
        label="Планируемая дата завершения"
        name={["range", "ends_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
      <DateTimeRangePickerWithTimeControls
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default ConsolidatedFormFields;
