import React from "react";
import { Longread } from "@/models";
import { Flex, Grid, Spin, theme, Typography } from "antd";
import ReactQuill from "react-quill";
import useSWR from "swr";
import { AxiosRequestConfig } from "axios";
import axios from "@/axios";
import { OrionRestShowResponse } from "@/shared/types/orion-rest.tsx";

const { useBreakpoint } = Grid;

type LongreadDescriptionsProps = {
  dataSource: Longread;
};

const LongreadDescriptions: React.FC<LongreadDescriptionsProps> = ({
  dataSource,
}) => {
  const { token } = theme.useToken();
  const screens = useBreakpoint();
  const { data: longread, ...longreadRequest } = useSWR(
    {
      url: `/api/longreads/${dataSource.id}`,
      params: { include: "content_file" },
    } as AxiosRequestConfig,
    async (config: AxiosRequestConfig) => {
      return axios
        .request<OrionRestShowResponse<Longread>>(config)
        .then((res) => res.data.data);
    },
  );
  const { data: content, ...contentRequest } = useSWR(
    longread?.content_file?.url ?? null,
    (url: string) => {
      return axios.get(url).then((res) => res.data);
    },
  );

  if (longreadRequest.isLoading) return <Spin />;
  if (longreadRequest.error) throw longreadRequest.error;
  if (contentRequest.error) throw contentRequest.error;
  if (!longread) throw new Error("Longread is not found");

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: `calc(100% ${!screens.lg ? "- 32px - 8px" : ""})`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {longread.name}
      </Typography.Title>
      {contentRequest.isLoading && <Spin />}
      {!contentRequest.isLoading && (
        <div
          style={{
            flex: "auto",
            overflowY: "auto",
            width: "100%",
            backgroundColor: token.colorWhite,
            borderRadius: token.borderRadius,
          }}
        >
          <ReactQuill
            theme={"bubble"}
            value={content}
            readOnly
            style={{
              color: "rgba(0, 0, 0, 0.88)",
              width: "100%",
              // @ts-ignore
              overflowY: "auto -webkit-scrollbar",
            }}
          />
        </div>
      )}
    </Flex>
  );
};

export default LongreadDescriptions;
