export const MIN_COMMISSION_MEMBERS_COUNT = 3;
export const MAX_COMMISSION_MEMBERS_COUNT = 10;

export const MIN_HEAD_COMMISSION_MEMBERS_COUNT = 1;
export const MAX_HEAD_COMMISSION_MEMBERS_COUNT = 1;

export const MIN_MEMBER_COMMISSION_MEMBERS_COUNT =
  MIN_COMMISSION_MEMBERS_COUNT - MIN_HEAD_COMMISSION_MEMBERS_COUNT;

export const MAX_MEMBER_COMMISSION_MEMBERS_COUNT =
  MAX_COMMISSION_MEMBERS_COUNT - MAX_HEAD_COMMISSION_MEMBERS_COUNT;

export const MAX_COURSE_COMMISSION_COUNT = 10;
