import React from "react";
import { Outlet } from "react-router-dom";
import VersionContextProvider from "@/entities/version/lib/context-provider.tsx";
import ModeContextProvider from "@/entities/mode/lib/context-provider.tsx";
import FeaturesContextProvider from "@/entities/features/lib/context-provider.tsx";
import SyncUserFromAuthToSentry from "@/shared/contexts/sync-user-from-auth-to-sentry.tsx";
import ContextAuth from "@/pages/context-auth.tsx";
import ContextDepsLoader from "./context-deps-loader";
import ContextImpersonationCleanUrlParams from "./context-impersonation-clean-url-params";

const Context: React.FC = () => {
  return (
    <ContextImpersonationCleanUrlParams>
      <VersionContextProvider>
        <ModeContextProvider>
          <FeaturesContextProvider>
            <ContextAuth>
              <ContextDepsLoader>
                <SyncUserFromAuthToSentry>
                  <Outlet />
                </SyncUserFromAuthToSentry>
              </ContextDepsLoader>
            </ContextAuth>
          </FeaturesContextProvider>
        </ModeContextProvider>
      </VersionContextProvider>
    </ContextImpersonationCleanUrlParams>
  );
};

export default Context;
