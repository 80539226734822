import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import { Member, MemberPosition, TreeNode } from "@/models";
import {
  ProForm,
  ProFormSelectProps,
  ProFormTreeSelect,
} from "@ant-design/pro-components";
import useMe from "@/entities/me/lib/use";
import useFeatures from "@/entities/features/lib/use";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";

type MemberPositionInputProps = React.ComponentProps<
  typeof ProFormTreeSelect
> & {
  memberId: Member["id"];
  listName?: ProFormSelectProps["name"];
};

type DataNode = {
  value: string | number;
  title: string;
  type: TreeNode["is_an_type"];
  icon: React.ReactNode;
  children?: DataNode[];
  disabled: boolean;
};

const MemberPositionSelect: React.FC<MemberPositionInputProps> = ({
  memberId,
  listName,
  fieldProps,
  disabled,
  ...props
}) => {
  const me = useMe();
  const form = ProForm.useFormInstance();
  const value = ProForm.useWatch(listName ?? props.name, form);

  const {
    data: positions,
    isLoading,
    error,
  } = useSWR(
    memberId ? ["/api/member-positions/search", memberId] : null,
    async ([url]) => {
      const filters: any = [
        {
          field: "member_id",
          operator: "=",
          value: memberId,
        },
      ];

      const res = await axios
        .post<{ data: MemberPosition[] }>(url, {
          filters,
          includes: [
            { relation: "position.tree_node.is_an" },
            { relation: "position.tree_node.reverse_tree.is_an" },
          ],
        })
        .then(async (res) => {
          const data: DataNode[][] = [];

          const extractReverseTreeData = (
            node: TreeNode,
            result: DataNode[] = [],
          ): DataNode[] => {
            if (!node) return result;

            result.push({
              title: node.is_an.name,
              type: node.is_an_type,
              icon: (
                <OrgStructureIcon
                  type={node.is_an_type as "department" | "position"}
                />
              ),
              value:
                node.is_an_type === "position"
                  ? node.is_an_id
                  : `${node.is_an_type}#${node.is_an_id}`,
              disabled:
                node.is_an_type === "department"
                  ? true
                  : me.is_restricted_access &&
                    !me.accessed_positions?.includes(node.is_an_id),
            });

            if (node.reverse_tree) {
              extractReverseTreeData(node.reverse_tree, result);
            }
            return result;
          };

          res.data.data.forEach((memberPosition) => {
            if (!memberPosition.position) return;
            const tree = extractReverseTreeData(
              // @ts-ignore
              memberPosition.position.tree_node,
            );
            data.push(tree.reverse());
          });

          return data;
        });

      return res;
    },
  );

  if (error) throw error;
  const buildTree = (data: DataNode[][]) => {
    const result: DataNode[] = [];
    const map: any = {};

    data.forEach((path) => {
      let currentLevel = result;
      let fullTitle = "";

      path.forEach((item, index) => {
        const key = `${item.type}-${item.value}`;

        if (item.type === "department") {
          fullTitle += (fullTitle ? ", " : "") + item.title;
        }

        if (!map[key]) {
          const newItem = { ...item };

          if (item.type === "position") {
            newItem.title = `${fullTitle ? `${fullTitle}, ` : ""} ${
              item.title
            }`;
          }

          map[key] = { ...newItem, children: [] };
          currentLevel.push(map[key]);
        }

        currentLevel = map[key].children;

        if (index === path.length - 1) {
          delete map[key].children;
        }
      });
    });

    return result;
  };

  return (
    <ProFormTreeSelect
      allowClear
      disabled={disabled || (value && positions?.length === 1)}
      fieldProps={{
        treeData: buildTree(positions ?? []),
        filterTreeNode: true,
        treeIcon: true,
        showSearch: true,
        autoClearSearchValue: true,
        treeNodeFilterProp: "title",
        fieldNames: {
          label: "title",
        },
        ...fieldProps,
      }}
      {...props}
    />
  );
};

export default MemberPositionSelect;
