import React, { useState } from "react";
import { Member } from "@/models";
import axios from "@/axios";
import MemberForm from "@/entities/member/ui/form";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { Tag, Tooltip, Typography } from "antd";
import useSpace from "@/entities/space/lib/use";
import MemberResetPasswordButton from "@/features/member/reset-password/ui/button";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum.tsx";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";
import useParentHeight from "@/shared/hooks/use-parent-height";
import useMe from "@/entities/me/lib/use";
import { useTranslation } from "react-i18next";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import { ProFormSelect } from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import RolesSearchSelect from "@/entities/roles/select";
import GroupSearchSelect from "@/entities/group/ui/select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import useFeatures from "@/entities/features/lib/use";
import { createPositionFilter } from "../lib/create-position-filter";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";

type Params = {
  role: string;
};

type MembersTableProps = ProTableProps<Member, Params>;

const MembersTable: React.FC<MembersTableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const features = useFeatures();

  const space = useSpace();
  const member = useMe();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { parentHeight, ref } = useParentHeight("table");
  const [total, setTotal] = React.useState<number>();

  const defaultProps: MembersTableProps = {
    rowKey: "id",
    pagination: { showSizeChanger: true },
    options: false,
    toolBarRender: (action, { selectedRowKeys }) => {
      if (action === undefined) {
        throw new Error("action is undefined");
      }

      if (selectedRowKeys === undefined) {
        throw new Error("selectedRowKeys is undefined");
      }

      return [
        member.permissions.includes("member:reset_password") && (
          <MemberResetPasswordButton
            key="reset-password"
            membersIds={selectedRowKeys.map(Number)}
            onAfterFinish={() => action.reload()}
          />
        ),
        member.permissions.includes("pool_participant:create") && (
          <MemberEducationPurposeModalForm
            key="education-purpose"
            membersIds={selectedRowKeys.map(Number)}
          />
        ),
        member.permissions.includes("export:export_members") && (
          <ExportButton
            metaFields={{ members_ids: selectedRowKeys.map(Number) }}
            type="export_members"
          />
        ),
        member.permissions.includes("import:members") && (
          <ImportButton
            key="import"
            type="members"
            onAfterFinish={() => action.reload()}
          />
        ),
        member.permissions.includes("member:create") && (
          <MemberForm
            rest={{
              type: "create",
              onAfterCreate: () => action.reload(),
            }}
            modal
          />
        ),
      ];
    },
    request: async (
      params,
      sort,
      filter: Record<string, (string | number | null)[] | null>,
    ) => {
      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const {
        ["positions,position_id"]: positionIds,
        ["groups,id"]: groupsIds,
        ...filters
      } = filter;

      const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/members/search`,
        ...axiosConfigAdapter(params, sort, filters),
      };

      if (positionIds) {
        config.data.filters.push(
          createPositionFilter(positionIds, "positions.position_id"),
        );
      }

      if (groupsIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInGroups",
          parameters: [groupsIds.includes(null), groupsIds],
        });
      }

      config.data.sort.push({
        field: "id",
        direction: "desc",
      });

      config.data.includes.push(
        { relation: "positions.position" },
        { relation: "positions.position.tree_node.reverse_tree.is_an" },
        { relation: "groups" },
        { relation: "role" },
      );
      const data = await axios
        .request<OrionRestIndexResponse<Member>>(config)
        .then((res) => res.data);

      setTotal(data.meta.total);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    rowSelection: {
      preserveSelectedRowKeys: true,
      selectedRowKeys,
      onChange: (keys) => {
        setSelectedRowKeys(keys);
      },
    },
    scroll: {
      y: parentHeight + (total ? 0 : 40) - (selectedRowKeys.length ? 225 : 165),
    },
    columns: [
      {
        title: "ФИО",
        dataIndex: "full_name",
        sorter: true,
      },
      {
        title: "Роль",
        dataIndex: "role_id",
        render: (_, { role }) => (
          <Tag color={role.color} bordered>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{ color: invertColor(role.color), maxWidth: "120px" }}
            >
              {role.name}
            </Typography.Text>
          </Tag>
        ),
        renderFormItem(_, config) {
          return <RolesSearchSelect label={null} {...config} mode="multiple" />;
        },
      },
      {
        title: "Почта",
        dataIndex: "email",
        hideInTable: !space.authentication_types.includes("email+password"),
        hideInSearch: !space.authentication_types.includes("email+password"),
        copyable: true,
      },
      {
        title: "Логин",
        dataIndex: "username",
        hideInTable: !space.authentication_types.includes("username+password"),
        hideInSearch: !space.authentication_types.includes("username+password"),
        copyable: true,
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: statusValueEnum,
        defaultFilteredValue: ["active"],
        renderFormItem(_, config) {
          return (
            <ProFormSelect
              {...config}
              fieldProps={{ tagRender }}
              mode="multiple"
            />
          );
        },
      },
      {
        title: t("Должность"),
        order: features.isEnabled("restrict_accesses") ? 1 : undefined,
        dataIndex: ["positions", "position_id"],
        renderText: (_, { positions }) => {
          if (positions && positions.length === 0) {
            return "-";
          }

          const positionNames = positions?.map(({ position }) => {
            const name = position?.name;
            // @ts-ignore
            const treeNodeName = getTreeNodeNames(position?.tree_node);

            return name
              ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
              : "-";
          });

          return (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={positionNames?.join(";\n")}
            >
              <Typography.Text
                ellipsis
                style={{
                  maxWidth: "300px",
                  width: "100%",
                }}
              >
                {positionNames?.join("; ")}
              </Typography.Text>
            </Tooltip>
          );
        },

        renderFormItem(_, config) {
          return (
            <OrgStructureTreeSelect
              fieldProps={{ multiple: true }}
              labelWithPath
              searchUser
              {...config}
            />
          );
        },
      },
      {
        title: "Группa",
        dataIndex: ["groups", "id"],
        render: (_, { groups }) => {
          return (
            <Typography.Text ellipsis={{ tooltip: true }}>
              {groups?.map((el) => el.name).join(", ")}
            </Typography.Text>
          );
        },
        renderFormItem(_, config) {
          return <GroupSearchSelect label={null} {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Дата добавления",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          tooltip: false,
          name: ["range", "created_at"],
        },
        renderFormItem(_, config) {
          return (
            <DateTimeRangePickerWithTimeControls
              name={["range", "created_at"]}
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <ProTable<Member, Params> {...props} />
    </div>
  );
};

export default MembersTable;
export type { MembersTableProps };
