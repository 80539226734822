import React, { useEffect, useState } from "react";
import { Scorm } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import axios from "@/axios";
import {
  Flex,
  message,
  Modal,
  Result,
  Space,
  Spin,
  theme,
  Tooltip,
  Typography,
  UploadFile,
} from "antd";
import { ButtonProps } from "antd/es/button";
import { RestProps } from "@/shared/rest/lib/types";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import { ReloadOutlined } from "@ant-design/icons";
import Button from "@/shared/ant-design/button/ui/button";
import statusValueEnum from "@/entities/scorm/lib/status-value-enum";
import useMe from "@/entities/me/lib/use";
import UploadDraggerWithPreview from "@/entities/file/ui/upload-with-preview";

type FormData = Scorm & {
  package_file: (File & UploadFile)[];
};

type Props = ProFormProps<FormData> & {
  rest: RestProps<Scorm>;
  onDelete?: () => void;
};

const ScormForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const { token } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const member = useMe();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [form] = ProForm.useForm<FormData>();
  const packageFile = ProForm.useWatch("package_file", form);
  const [modal, modalHolder] = Modal.useModal();

  const status = ProForm.useWatch("status", form);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const onFinish = async (values: Scorm) => {
    if (packageFile && packageFile.length > 0) {
      for (let i = 0; i < packageFile.length; i++) {
        const item: any = packageFile[i];
        if (!item.originFileObj || (item.status && item.status !== "done"))
          continue;
        const key = item.response.key;
        await axios
          .post(`/api/files`, {
            key,
            name: item.name,
            type: item.type,
          })
          .then(({ data }) => {
            packageFile[i] = data.data;
            return data.data;
          })
          .then((file) => {
            return axios.put<OrionRestUpdateResponse<Scorm>>(
              `/api/scorms/${values.id}`,
              { package_file_id: file.id },
            );
          });
      }
    } else if (values.package_file_id) {
      await axios.put<OrionRestUpdateResponse<Scorm>>(
        `/api/scorms/${values.id}`,
        { package_file_id: null },
      );
    }
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    const request = async () => {
      return axios
        .get<OrionRestShowResponse<Scorm>>(`/api/scorms/${rest.recordKey}`, {
          params: { include: "package_file" },
        })
        .then((res) => {
          return {
            ...res.data.data,
            package_file: res.data.data.package_file
              ? ([{ ...res.data.data.package_file, status: "done" }] as any)
              : [],
          };
        })
        .catch((err) => {
          setError(err);
          throw err;
        });
    };

    defaultProps.request = async () => {
      setIsLoading(true);
      return request().finally(() => setIsLoading(false));
    };
    defaultProps.onFinish = async (values) => {
      setIsSubmitting(true);
      return axios
        .put<OrionRestUpdateResponse<Scorm>>(
          `/api/scorms/${rest.recordKey}`,
          values,
        )
        .then(async (res) => {
          await onFinish(res.data.data);
          const {
            data: { data: scorm },
          } = await axios.get<OrionRestShowResponse<Scorm>>(
            `/api/scorms/${rest.recordKey}`,
            { params: { include: "package_file" } },
          );
          message.success("SCORM успешно обновлен");
          await rest.onAfterUpdate?.(scorm);
          await request?.().then((res) => form.setFieldsValue(res as any));
          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении SCORM'а: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete: ButtonProps["onClick"] = async () => {
    modal.warning({
      title: "Удалить текущий SCORM?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await axios
          .delete(`/api/scorms/${props.id}`)
          .then(() => {
            onDeleteProp?.();
            message.success("SCORM успешно удалён");
          })
          .catch((err) => {
            console.error(err);
            message.error(
              err.response.data.message ?? "Не удалось удалить SCORM",
            );
          });
      },
    });
  };

  const [isRefreshing, setIsRefreshing] = useState(false);
  const refresh = (
    fields: undefined | (keyof Scorm)[] | keyof Scorm,
  ): Promise<Scorm> => {
    setIsRefreshing(true);

    return (async () => {
      const request = props.request;

      if (!request) {
        throw new Error("Request not found");
      }

      return request({}, {}).then((data) => {
        if (!fields) {
          form.setFieldsValue(data);
          return data;
        }

        if (!Array.isArray(fields)) {
          fields = [fields];
        }

        for (const field of fields) {
          if (!(field in data)) {
            throw new Error(`Field ${field} not found`);
          }
          form.setFieldValue(field, data[field]);
        }

        return data;
      });
    })()
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };
  const refreshInterval: undefined | number = 1000 * 5;

  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "to_deploy" || status === "deploying") {
        refresh("status");
      }
    }, refreshInterval);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <Result status={"error"} title={"Ошибка"} subTitle={error.message} />
    );
  }

  return (
    <Spin spinning={isLoading}>
      {modalHolder}
      <ProForm<FormData> {...props}>
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          {member.permissions.includes("course:update") && (
            <>
              <Button danger type={"primary"} onClick={onDelete}>
                Удалить
              </Button>
              <Button
                type={"primary"}
                onClick={form.submit}
                loading={isSubmitting}
              >
                Сохранить
              </Button>
            </>
          )}
        </Flex>
        <ProFormDigit name="course_id" label="ID курса" hidden />
        <ProFormText
          name="name"
          label="Название"
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormTextArea name="description" label="Описание" />
        <UploadDraggerWithPreview
          name={"package_file"}
          label={"Пакет"}
          title={"Загрузите файл"}
          description={"Перетащите файл в эту область или нажмите на нее"}
          rules={[
            { required: true },
            () => ({
              validator(_, files) {
                for (const file of files) {
                  if (!file.type) {
                    return Promise.reject(
                      new Error("Не удалось определить тип файла"),
                    );
                  }

                  if (file.name.length > 255) {
                    return Promise.reject(
                      new Error("Слишком длинное название файла"),
                    );
                  }

                  const isZip = [
                    "application/zip",
                    "application/x-zip-compressed",
                  ].includes(file.type);

                  if (!isZip) {
                    return Promise.reject(
                      new Error(
                        "Недопустимый тип файла: Можно загружать только ZIP-архивы",
                      ),
                    );
                  }
                }

                return Promise.resolve();
              },
            }),
          ]}
          max={1}
          fieldProps={{
            listType: "picture",
            accept: ".zip",
          }}
        />
        <ProFormText name="status" hidden />
        <ProFormDigit name="package_file_id" hidden />
        <Flex justify={"center"} gap={16} style={{ width: "100%" }}>
          <Space direction={"horizontal"}>
            <Typography.Text type={"secondary"}>
              Статус: {statusValueEnum[status] ?? status}
            </Typography.Text>
            {(status === "to_deploy" || status === "deploying") && (
              <Tooltip title="Обновить статус">
                <Button
                  icon={
                    <ReloadOutlined
                      style={{ color: token.colorTextSecondary }}
                    />
                  }
                  onClick={() => refresh("status")}
                  type={"text"}
                  size={"small"}
                  loading={isRefreshing}
                />
              </Tooltip>
            )}
          </Space>
        </Flex>
      </ProForm>
    </Spin>
  );
};

export default ScormForm;
