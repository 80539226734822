import React, { useState } from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Course, CourseCommission, File } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { Button, Flex, message, Typography } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useFeatures from "@/entities/features/lib/use.ts";
import { UploadFile } from "antd/lib";
import UploadDraggerWithPreview from "@/entities/file/ui/upload-with-preview";
import CommissionSearchSelect from "@/entities/commission/ui/select-with-swr";
import useMe from "@/entities/me/lib/use";
import { FieldData } from "rc-field-form/es/interface";

type FormData = Omit<Course, "cover_image_file"> & {
  cover_image_file?: (File & UploadFile)[] | null;
};

type CourseFormProps = ProFormProps<FormData> & {
  rest: RestProps<Course>;
};

const CourseForm: React.FC<CourseFormProps> = ({ rest, ...props }) => {
  const features = useFeatures();
  const [form] = ProForm.useForm<FormData>(props.form);
  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const commissions = ProForm.useWatch("course_commissions", form);
  const [toDeleteCourseCommissions, setToDeleteCourseCommissions] =
    React.useState<CourseCommission["id"][]>([]);

  const member = useMe();

  const checkDublicateCommissions = (commissions: CourseCommission[]) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;
    commissions.forEach((item, index) => {
      if (!item.commission_id) return;
      if (item.commission_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.commission_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_commissions", index, "commission_id"],
            errors: [
              "Комиссия дублируется, выберите другую комиссию или удалите поле",
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const syncFiles = async (formData: FormData) => {
    if (formData.cover_image_file && formData.cover_image_file.length > 0) {
      if (formData.cover_image_file.length > 1) {
        throw new Error("Only one image file is allowed");
      }

      if (formData.cover_image_file.length === 1) {
        const imageFile = formData.cover_image_file[0];
        if (!imageFile.id) {
          await axios
            .post<OrionRestCreateResponse<File>>(`/api/files`, {
              key: imageFile.response.key,
              name: imageFile.name,
              type: imageFile.type,
            })
            .then((res) => res.data.data)
            .then((file) => {
              return axios.put<OrionRestUpdateResponse<Course>>(
                `/api/courses/${formData.id}`,
                { cover_image_file_id: file.id },
              );
            });
        }
      }
    } else if (formData.cover_image_file_id) {
      await axios.put<OrionRestUpdateResponse<Course>>(
        `/api/courses/${formData.id}`,
        { cover_image_file_id: null },
      );
    }
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
    });
    defaultProps.onFinish = async (values) => {
      /** Check duplicate commissions */
      if (
        values.course_commissions &&
        checkDublicateCommissions(values.course_commissions)
      ) {
        return;
      }

      return await axios
        .post<OrionRestCreateResponse<Course>>("/api/courses", values)
        .then(async (res) => {
          await syncFiles({ ...values, id: res.data.data.id });
          return res.data.data;
        })
        .then(async (data) => {
          const toCreateCourseCommissions = values.course_commissions;

          if (
            toCreateCourseCommissions &&
            toCreateCourseCommissions.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toCreateCourseCommissions.map((el) => {
                    return {
                      ...el,
                      course_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then((data) => {
          message.success("Курс успешно создан");
          rest.onAfterCreate?.(data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании курса",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.submitter = false;
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Course>>(
          `/api/courses/${rest.recordKey}?include=cover_image_file,course_commissions.commission`,
        )
        .then((res) => {
          const data = res.data.data;
          return {
            ...data,
            cover_image_file: data.cover_image_file
              ? [{ ...data.cover_image_file, status: "done" }]
              : [],
          } as FormData;
        });
    };
    defaultProps.onFinish = async (values) => {
      /** Check duplicate commissions */
      if (
        values.course_commissions &&
        checkDublicateCommissions(values.course_commissions)
      ) {
        return;
      }

      setLoading(true);

      return axios
        .put<OrionRestUpdateResponse<Course>>(
          `/api/courses/${rest.recordKey}`,
          values,
        )
        .then(async (res) => {
          await syncFiles({ ...values, id: res.data.data.id });
          return res.data.data;
        })
        .then(async (data) => {
          if (toDeleteCourseCommissions?.length) {
            await axios
              .delete(`/api/course-commissions/batch`, {
                data: {
                  resources: toDeleteCourseCommissions,
                },
              })
              .then(() => {
                setToDeleteCourseCommissions([]);
              })
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка удаления курсов коммиссии",
                );
                throw err;
              });
          }

          return data;
        })
        .then(async (data) => {
          const toUpdateCourseCommissions = values.course_commissions?.filter(
            (el) => el.id,
          );

          if (
            toUpdateCourseCommissions &&
            toUpdateCourseCommissions.length > 0
          ) {
            await axios
              .patch<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toUpdateCourseCommissions
                    .map((el) => {
                      return {
                        course_id: data.id,
                        commission_id: el.commission_id ?? null,
                        id: el.id ?? null,
                      };
                    })
                    .reduce((acc, courseCommission) => {
                      acc[courseCommission.id] = courseCommission;
                      return acc;
                    }, {} as Record<string, Partial<CourseCommission>>),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка обновления курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then(async (data) => {
          const toCreateCourseCommissions = values.course_commissions?.filter(
            (el) => !el.id,
          );

          if (
            toCreateCourseCommissions &&
            toCreateCourseCommissions.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toCreateCourseCommissions.map((el) => {
                    return {
                      ...el,
                      course_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then((data) => {
          message.success("Курс успешно обновлён");
          rest.onAfterUpdate?.(data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении курса: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(async () => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          setLoading(false);
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <>
      {rest.type === "update" && (
        <Flex justify={"end"} gap={16}>
          <Button
            loading={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            Сохранить
          </Button>
        </Flex>
      )}
      <ProForm<FormData> {...props}>
        <ProFormText
          name="name"
          label="Название"
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormDigit name={"cover_image_file_id"} hidden />

        {features.isEnabled("courses_keys") && (
          <ProFormText
            name="key"
            label="Идентификатор"
            rules={[
              { max: 100 },
              {
                pattern: /^[^\s]+$/,
                message: "Идентификатор не должен содержать пробелы",
              },
            ]}
          />
        )}
        <ProFormTextArea name="description" label="Описание" />
        <UploadDraggerWithPreview
          label="Обложка"
          name="cover_image_file"
          title="Загрузите файл"
          description="Перетащите файл в эту область или нажмите на нее"
          max={1}
          rules={[
            { required: false },
            () => ({
              validator(_, files) {
                if (files) {
                  for (const file of files) {
                    const oneMb = 1048000;
                    if (file.size > oneMb) {
                      return Promise.reject(
                        new Error("Необходимо выбрать файл меньше 1 МБ"),
                      );
                    }

                    if (!file.type) {
                      return Promise.reject(
                        new Error("Не удалось определить тип файла"),
                      );
                    }

                    const isImage = [
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                    ].includes(file.type);

                    if (!isImage) {
                      return Promise.reject(
                        new Error(
                          "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
                        ),
                      );
                    }
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
          fieldProps={{
            listType: "picture",
            accept: ".png, .jpg, .jpeg",
          }}
        />
        {member.permissions.includes("commission:update") && (
          <ProFormList
            style={{ width: "100%" }}
            creatorButtonProps={{
              creatorButtonText: "Добавить еще",
              type: "primary",
              block: false,
              icon: false,
            }}
            name="course_commissions"
            max={10}
            containerStyle={{ width: "100%" }}
            label={<Typography.Text strong>Комиссии</Typography.Text>}
            copyIconProps={false}
            deleteIconProps={{ tooltipText: "Удалить" }}
            onAfterRemove={async (index) => {
              if (
                commissions &&
                typeof index === "number" &&
                commissions[index].id
              ) {
                setToDeleteCourseCommissions((prev) => [
                  ...prev,
                  commissions[index].id,
                ]);
              }
            }}
          >
            {(_, index) => (
              <>
                <CommissionSearchSelect
                  listName={["course_commissions", index, "commission_id"]}
                  name={"commission_id"}
                  rules={[{ required: true }]}
                  withExternalLink
                />
                <ProFormDigit hidden name="id" />
              </>
            )}
          </ProFormList>
        )}
      </ProForm>
    </>
  );
};

export default CourseForm;
export type { CourseFormProps };
