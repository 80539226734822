import { MemberImport } from "@/entities/import/lib/model";
import { Alert, Space, theme, Typography } from "antd";
import React from "react";

export type ImportResultProps = {
  result: MemberImport["result"];
};

const ButtonMembersResult: React.FC<ImportResultProps> = ({ result }) => {
  const { token } = theme.useToken();

  if (result.members && !Array.isArray(result.members)) {
    result.members = [result.members];
  }

  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        paddingBlock: token.padding,
        maxHeight: "50vh",
        overflow: "auto",
      }}
    >
      {result.members
        ? (result.members ?? []).map((result, index) => {
            if (
              result.errors ||
              !(result.operation === "update" || result.operation === "create")
            ) {
              return (
                <Alert
                  key={index + result.message}
                  message={result.message}
                  showIcon
                  type={"error"}
                  description={
                    <Space direction={"vertical"}>
                      {result.errors?.map((error, index) => (
                        <Typography.Text key={error + index}>
                          {error}
                        </Typography.Text>
                      ))}
                    </Space>
                  }
                />
              );
            } else if (
              result.operation === "update" ||
              result.operation === "create"
            ) {
              return (
                <Alert
                  key={index + result.message}
                  message={<Typography.Text>{result.message}</Typography.Text>}
                  showIcon
                  type={result.operation === "create" ? "success" : "info"}
                />
              );
            }
          })
        : "При импорте не требовалось создать и/или изменить пользователей"}
    </Space>
  );
};

export default ButtonMembersResult;
