import React from "react";
import { Export } from "@/entities/export/lib/model.ts";
import Button from "@/shared/ant-design/button/ui/button";
import { useExport } from "../lib/use-export";

type ExportButtonProps = {
  metaFields?: Export["meta_fields"];
  type: Export["type"];
} & {
  trigger?: JSX.Element;
};

const ExportButton: React.FC<ExportButtonProps> = ({
  metaFields,
  type,
  trigger = <Button type={"primary"}>Экспорт</Button>,
}) => {
  const { messageContextHolder, modalContextHolder, exportEntity } =
    useExport();

  return (
    <>
      {modalContextHolder}
      {messageContextHolder}
      {React.cloneElement(trigger, {
        onClick: () => exportEntity(type, metaFields),
      })}
    </>
  );
};

export default ExportButton;
