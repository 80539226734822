import React, { useCallback, useState } from "react";
import { ProForm, ProFormList, ProFormProps } from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import {
  message,
  Space,
  Tabs as AntdTabs,
  TabsProps,
  theme,
  Typography,
  Button,
  Flex,
  Spin,
} from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { Commission } from "../lib/model";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import styled from "styled-components";
import CourseSearchSelect from "@/entities/course/ui/select-with-swr";
import { CommissionMember, CourseCommission } from "@/models";
import useMe from "@/entities/me/lib/use";
import { FieldData } from "rc-field-form/es/interface";
import CommissionFormFields from "./form-fields";
import { MAX_COURSE_COMMISSION_COUNT } from "../lib/constants";

const Tabs = styled(AntdTabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

const SpinWrapper = styled.div`
  height: 100%;
  width: 100%;

  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
`;

type CommissionFormProps = ProFormProps<Commission> & {
  rest: RestProps<Commission>;
};

const CommissionForm: React.FC<CommissionFormProps> = ({ rest, ...props }) => {
  const { token } = theme.useToken();
  const [form] = ProForm.useForm<Commission>(props.form);
  const member = useMe();

  const [toDeleteCourseCommissions, setToDeleteCourseCommissions] =
    React.useState<CourseCommission["id"][]>([]);
  const [toDeleteCommissionMembers, setToDeleteCommissionMembers] =
    React.useState<CommissionMember["id"][]>([]);
  const [loading, setLoading] = useState(false);
  const courseCommissions = ProForm.useWatch("course_commissions", form);

  const setDeleteMembers = (id: CommissionMember["id"]) => {
    setToDeleteCommissionMembers((prev) => [...prev, id]);
  };

  const checkDublicateCourse = (courses: CourseCommission[]) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;
    courses.forEach((item, index) => {
      if (!item.course_id) return;
      if (item.course_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.course_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_commissions", index, "course_id"],
            errors: ["Курс дублируется, выберите другой курс или удалите поле"],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const checkDublicateCommissionMembers = (members: CommissionMember[]) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;
    members.forEach((item, index) => {
      if (!item.member_id) return;
      if (item.member_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.member_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["members", index - 1, "member_id"],
            errors: [
              "Участник дублируется, выберите другого участника или удалите поле",
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  let defaultProps: Partial<typeof props> = {
    submitter: { resetButtonProps: false },
    preserve: false,
    grid: true,
    disabled: !member.permissions.includes("commission:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
      initialValues: {
        members: [{ type: "member" }, { type: "member" }],
        members_head: [{ type: "head" }],
      },
    });
    defaultProps.onFinish = async (values) => {
      /** Check duplicate members */
      if (
        checkDublicateCommissionMembers([
          ...values.members_head,
          ...(values.members || []),
        ])
      ) {
        return;
      }

      const { members, members_head, ...restValues } = values;
      return await axios
        .post<OrionRestCreateResponse<Commission>>(
          "/api/commissions",
          restValues,
        )
        .then(async (res) => {
          const commissionMembers = members
            ?.map((member) => ({ ...member, type: "member" }))
            .concat(
              members_head.map((member) => ({ ...member, type: "head" })),
            );
          commissionMembers?.forEach((commissionMember) => {
            commissionMember.commission_id = res.data.data.id;
          });
          await axios
            .post<OrionRestCreateResponse<any>>(
              `/api/commission-members/batch`,
              { resources: commissionMembers },
            )
            .catch((err) => {
              message.error(
                err.response.data.message ??
                  "Ошибка при создании участников коммиссии",
              );
              throw err;
            });

          return res.data.data;
        })
        .then((data) => {
          message.success("Коммиссия успешно создана");
          rest.onAfterCreate?.(data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании коммиссии",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.submitter = false;
    defaultProps.request = async () => {
      setLoading(true);
      return axios
        .get<OrionRestShowResponse<Commission>>(
          `/api/commissions/${rest.recordKey}?include=members,course_commissions`,
        )
        .then((res) => {
          const data = res.data.data;

          return {
            ...data,
            members: data.members?.filter((member) => member.type === "member"),
            members_head: data.members!.filter(
              (member) => member.type === "head",
            ),
          };
        })
        .finally(() => setLoading(false));
    };
    defaultProps.onFinish = async (values) => {
      /** Check duplicate courses */
      if (
        values.course_commissions &&
        checkDublicateCourse(values.course_commissions)
      ) {
        return;
      }
      /** Check duplicate members */
      if (
        checkDublicateCommissionMembers([
          ...values.members_head,
          ...(values.members || []),
        ])
      ) {
        return;
      }

      setLoading(true);
      await form.validateFields();

      return axios
        .put<OrionRestUpdateResponse<Commission>>(
          `/api/commissions/${rest.recordKey}`,
          values,
        )
        .then(async (res) => {
          message.success("Коммиссия успешно обновлена");
          return res.data.data;
        })
        .then(async (data) => {
          if (toDeleteCourseCommissions?.length) {
            await axios
              .delete(`/api/course-commissions/batch`, {
                data: {
                  resources: toDeleteCourseCommissions,
                },
              })
              .then(() => {
                setToDeleteCourseCommissions([]);
              })
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка удаления курсов коммиссии",
                );
                throw err;
              });
          }

          const toCreateCommissionMembers = [
            ...(values.members
              ?.filter((member) => !member.id)
              .map((member) => ({ ...member, type: "member" })) || []),
            ...values.members_head
              .filter((member) => !member.id)
              .map((member) => ({ ...member, type: "head" })),
          ];

          if (toDeleteCommissionMembers?.length) {
            await axios
              .delete(`/api/commission-members/batch`, {
                data: {
                  resources: toDeleteCommissionMembers,
                },
              })
              .then(() => {
                setToDeleteCommissionMembers([]);
              })
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка удаления участников коммиссии",
                );
                throw err;
              });
          }

          if (
            toCreateCommissionMembers &&
            toCreateCommissionMembers.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CommissionMember>>(
                `/api/commission-members/batch`,
                {
                  resources: toCreateCommissionMembers.map((el) => {
                    return {
                      ...el,
                      commission_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания участников коммиссии",
                );
                throw err;
              });
          }

          return data;
        })
        .then(async (data) => {
          const toUpdateCourseCommissions = values.course_commissions?.filter(
            (el) => el.id,
          );

          if (
            toUpdateCourseCommissions &&
            toUpdateCourseCommissions.length > 0
          ) {
            await axios
              .patch<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toUpdateCourseCommissions
                    .map((el) => {
                      return {
                        course_id: el.course_id ?? null,
                        commission_id: el.commission_id ?? null,
                        id: el.id ?? null,
                      };
                    })
                    .reduce((acc, courseCommission) => {
                      acc[courseCommission.id] = courseCommission;
                      return acc;
                    }, {} as Record<string, Partial<CourseCommission>>),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка обновления курсов коммиссии",
                );
                throw err;
              });
          }

          const toCreateCourseCommissions = values.course_commissions?.filter(
            (el) => !el.id,
          );

          if (
            toCreateCourseCommissions &&
            toCreateCourseCommissions.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toCreateCourseCommissions.map((el) => {
                    return {
                      ...el,
                      commission_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания курсов коммиссии",
                );
                throw err;
              });
          }

          return data;
        })
        .then(async (data) => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          rest.onAfterUpdate?.(data);
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении коммиссии: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  const Submitter: React.FC<{ disabled?: boolean }> = useCallback(
    ({ disabled }) => {
      return (
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          <Button
            disabled={disabled}
            type={"primary"}
            loading={loading}
            onClick={async () => {
              try {
                await form.validateFields();
              } catch (error: any) {
                const errorFieldName = error.errorFields[0].name[0];
                if (errorFieldName.startsWith("course_commissions")) {
                  setActiveTab("course_commissions");
                } else {
                  setActiveTab("commission");
                }
                return;
              }
              form.submit();
            }}
          >
            {rest.type === "create" ? "Создать" : "Сохранить"}
          </Button>
        </Flex>
      );
    },
    [loading, form],
  );

  const tabs: TabsProps["items"] = [
    {
      key: "commission",
      label: "Настройка",
      forceRender: true,
      style: {
        width: "100%",
        overflow: "hidden auto",
        height: "100%",
        paddingRight: token.paddingXS,
      },
      children: (
        <Space
          direction="vertical"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Submitter
            disabled={!member.permissions.includes("commission:update")}
          />
          <CommissionFormFields
            setDeleteMembers={setDeleteMembers}
            type={rest.type}
          />
        </Space>
      ),
    },
    {
      key: "course_commissions",
      label: "Курсы",
      forceRender: true,
      style: {
        width: "100%",
        overflow: "hidden auto",
      },
      children: (
        <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
          <Flex vertical style={{ width: "100%", height: "100%" }}>
            <Submitter
              disabled={!member.permissions.includes("commission:update")}
            />
            <ProFormList
              creatorButtonProps={{
                creatorButtonText: "Добавить еще",
                type: "primary",
                block: false,
                icon: false,
              }}
              name="course_commissions"
              max={MAX_COURSE_COMMISSION_COUNT}
              label={<Typography.Text strong>Курс</Typography.Text>}
              copyIconProps={false}
              deleteIconProps={
                member.permissions.includes("commission:update") && {
                  tooltipText: "Удалить",
                }
              }
              onAfterRemove={async (index) => {
                if (
                  courseCommissions &&
                  typeof index === "number" &&
                  courseCommissions[index].id
                ) {
                  setToDeleteCourseCommissions((prev) => [
                    ...prev,
                    courseCommissions[index].id,
                  ]);
                }
              }}
            >
              {(_, index) => (
                <CourseSearchSelect
                  withExternalLink
                  label=""
                  colProps={{ flex: "auto" }}
                  listName={["course_commissions", index, "course_id"]}
                  name={"course_id"}
                  rules={[{ required: true }]}
                />
              )}
            </ProFormList>
          </Flex>
        </Space>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useQueryTabs("commission", tabs);

  return (
    <SpinWrapper>
      <Spin spinning={loading}>
        <ProForm<Commission>
          {...props}
          style={{ height: "100%", width: "100%" }}
          rowProps={{ style: { height: "100%", width: "100%" } }}
        >
          {rest.type === "create" && (
            <Space
              style={{
                maxHeight: "85vh",
                overflowY: "auto",
                width: "100%",
                paddingRight: token.paddingXS,
              }}
              styles={{ item: { height: "100%", width: "100%" } }}
            >
              <CommissionFormFields type={rest.type} />
            </Space>
          )}
          {rest.type === "update" && (
            <Tabs
              style={{ width: "100%", height: "100%" }}
              activeKey={activeTab}
              items={tabs}
              destroyInactiveTabPane={false}
              onChange={setActiveTab}
            />
          )}
        </ProForm>
      </Spin>
    </SpinWrapper>
  );
};

export default CommissionForm;
export type { CommissionFormProps };
