import React from "react";
import { ButtonProps } from "antd/es/button";
import { Commission } from "@/models";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal } from "antd";

type CommissionDeleteButtonProps = ButtonProps & {
  afterRemove?: () => unknown | Promise<unknown>;
} & (
    | {
        recordKey: Commission["id"];
        recordKeys?: undefined;
      }
    | {
        recordKey?: undefined;
        recordKeys: Commission["id"][];
      }
  );

const CommissionDeleteButton: React.FC<CommissionDeleteButtonProps> = ({
  recordKey,
  recordKeys = [],
  afterRemove,
  ...props
}) => {
  const [modal, modalHolder] = Modal.useModal();

  if (recordKey) {
    recordKeys.push(recordKey);
  }

  const onDelete = () => {
    modal.confirm({
      title: `Вы уверены, что хотите удалить выбранные комиссии?`,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk: async () => {
        return await axios
          .delete(`/api/commissions/batch`, { data: { resources: recordKeys } })
          .then(async () => {
            afterRemove?.();
          })
          .catch((err) => {
            message.error(err.response.data.message ?? "Ошибка удаления");
          });
      },
    });
  };

  const defaultProps: ButtonProps = {
    danger: true,
    type: "primary",
    onClick: onDelete,
    disabled: recordKeys.length === 0,
  };

  props = deepmerge(defaultProps, props);

  return (
    <>
      {modalHolder}
      <Button {...props}>{props.children ?? "Удалить"}</Button>
    </>
  );
};

export default CommissionDeleteButton;
export type { CommissionDeleteButtonProps };
