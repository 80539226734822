import React from "react";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { ExportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import useMe from "@/entities/me/lib/use";
import SupportChatTable from "@/entities/support/ui/table";

const Page: React.FC = () => {
  const { parentHeight, ref } = useParentHeight("table");
  const [total, setTotal] = React.useState<number>();

  const member = useMe();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }} ref={ref}>
        <SupportChatTable
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          onLoad={(res) => setTotal(res.length)}
          columnsState={{
            persistenceKey: "support-chats-table",
          }}
          scroll={{
            y: parentHeight + (total ? 0 : 40) - 145,
          }}
          columns={[
            { dataIndex: "iid", align: "center" },
            { dataIndex: "status" },
            {
              dataIndex: "owner_id",
              render: (dom, { owner_full_name, owner_id, owner_type }) => {
                if (
                  owner_type === "anonymous" ||
                  !member.permissions.includes("member:view")
                ) {
                  return dom;
                }
                return (
                  <Link to={`/manage/members/${owner_id}`}>
                    {owner_full_name}
                  </Link>
                );
              },
            },
            { dataIndex: "created_at" },
            {
              title: "Действия",
              width: 150,
              align: "center",
              hideInSetting: true,
              disable: true,
              hideInSearch: true,
              render: (_, { iid }) => (
                <Link to={`/manage/support/chats/${iid}`}>
                  Перейти в чат <ExportOutlined />
                </Link>
              ),
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
