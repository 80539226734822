import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { deepmerge } from "deepmerge-ts";
import { Member } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSpace from "@/entities/space/lib/use";

type SelectProps = ProFormSelectProps;

type MemberSelectProps = SelectProps & {
  whereHaveInCommissions?: boolean;
};

const MemberSelect: React.FC<MemberSelectProps> = ({
  whereHaveInCommissions,
  ...selectProps
}) => {
  const space = useSpace();
  const defaultSelectProps: SelectProps = {
    debounceTime: 500,
    showSearch: true,
    request: async (params) => {
      return axios
        .post<OrionRestIndexResponse<Member>>("/api/members/search", {
          filters: [
            {
              field: "full_name",
              operator: "ilike",
              value: `%${params?.keyWords ?? ""}%`,
            },
          ],
          scopes: whereHaveInCommissions
            ? [
                {
                  name: "whereHaveInCommissions",
                },
              ]
            : [],
        })
        .then((r) =>
          r.data.data.map((d) => ({
            label: `${d.full_name}(${
              space.authentication_types.includes("email+password")
                ? d.email
                : d.username
            })`,
            value: d.id,
          })),
        );
    },
  };
  return <ProFormSelect {...deepmerge(defaultSelectProps, selectProps)} />;
};

export default MemberSelect;
export type { MemberSelectProps };
