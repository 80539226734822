import { useState } from "react";
import { message, Modal } from "antd";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import { Export } from "./model";
import axios from "@/axios";
import fileDownload from "js-file-download";

export const useExport = () => {
  const [error, setError] = useState<Error | null>(null);
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();

  const exportEntity = async (
    type: Export["type"],
    metaFields: Export["meta_fields"],
  ): Promise<boolean> => {
    let attempts = 120;

    let exportRecord = await axios
      .post<OrionRestCreateResponse<Export>>("/api/exports", {
        meta_fields: metaFields,
        status: "to_process",
        type,
      })
      .then((res) => res.data.data)
      .catch((error) => {
        message.error("Произошла ошибка при создании экспорта");
        setError(error);
        throw error;
      });

    if (exportRecord) {
      messageApi.open({
        type: "loading",
        content: `Идет экспорт №${exportRecord.id} ...`,
        duration: 0,
      });
      while (
        exportRecord.status !== "processed" &&
        exportRecord.status !== "failed" &&
        attempts--
      ) {
        exportRecord = await axios
          .get<OrionRestShowResponse<Export>>(
            `/api/exports/${exportRecord.id}?include=file`,
          )
          .then((res) => res.data.data)
          .catch((error) => {
            setError(error);
            throw error;
          });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      messageApi.destroy();
    }

    if (exportRecord.status === "failed") {
      if (Array.isArray(exportRecord.result)) {
        exportRecord.result?.forEach((result) => {
          message.error(result.message);
        });
      } else {
        modal.error({
          title: `Экспорт №${exportRecord.id} завершился с ошибкой`,
          width: "60%",
          content: exportRecord.result?.message,
          closable: true,
          footer: null,
          maskClosable: true,
        });
      }

      return Promise.resolve(false);
    }

    if (exportRecord.status === "processed") {
      let url = exportRecord.file?.url!;
      message.success(`Экспорт №${exportRecord.id} завершился`);

      axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          fileDownload(res.data, exportRecord.file?.name!);
        })
        .catch((error) => {
          setError(error);
          throw error;
        });

      return Promise.resolve(false);
    } else {
      message.error(`Ошибка при экпортировании №${exportRecord.id}`);
      return Promise.resolve(false);
    }
  };

  if (error) throw error;

  return {
    exportEntity,
    modalContextHolder,
    messageContextHolder,
  } as const;
};
