import React, { useRef } from "react";
import { Course } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import { AxiosRequestConfig } from "axios";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import CourseDeleteButton from "@/entities/course/ui/delete-button";
import CourseStatusChangeButton from "@/entities/course/ui/status-change-button";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { Button, Dropdown } from "antd";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { MenuProps } from "antd/lib";
import useImportModal from "@/entities/import/hooks/use-import-modal";
import { EllipsisOutlined } from "@ant-design/icons";
import useFeatures from "@/entities/features/lib/use.ts";
import CourseModalForm from "@/entities/course/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import { ProFormSelect } from "@ant-design/pro-components";
import { statusValueEnum } from "@/entities/course/lib/status-value-enum.tsx";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";

type Data = Course;

type Params = Partial<Course>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Course>;
};

const CoursesTable: React.FC<Props> = ({ rest, ...props }) => {
  const features = useFeatures();
  const action = useRef<ActionType>();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();

  const [registryImportModal, registryImportModalHolder] = useImportModal({
    type: "registry",
    massiveImports: true,
    trigger: <></>,
    onAfterFinish: () => action.current?.reload(),
  });

  const [courseImportModal, courseImportModalHolder] = useImportModal({
    type: "course",
    trigger: <></>,
    onAfterFinish: () => action.current?.reload(),
  });

  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/courses/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const data = await axios
      .request<OrionRestIndexResponse<Course>>(actionConfig)
      .then((res) => res.data);

    setTotal(data.meta.total);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const items: MenuProps["items"] = [
    ...(member.permissions.includes("import:course")
      ? [
          {
            label: "Импорт курса",
            key: "course",
            onClick: () => {
              courseImportModal.open();
            },
          },
        ]
      : []),
    ...(member.permissions.includes("import:registry")
      ? [
          {
            label: "Импорт номеров из реестра",
            key: "registry",
            onClick: () => {
              registryImportModal.open();
            },
          },
        ]
      : []),
  ];

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");

    return [
      member.permissions.includes("course:delete") && (
        <CourseDeleteButton
          key={"delete"}
          recordKeys={selectedRowKeys as number[]}
          afterRemove={() => {
            action.current?.reload();
            action.current?.clearSelected?.();
            rest?.onAfterDelete?.();
          }}
        />
      ),
      member.permissions.includes("course:update") && (
        <>
          <CourseStatusChangeButton
            key={"archived"}
            recordKeys={selectedRowKeys as number[]}
            status="to_archive"
            afterUpdate={() => {
              action.current?.reload();
              action.current?.clearSelected?.();
            }}
          />
          <CourseStatusChangeButton
            key={"active"}
            recordKeys={selectedRowKeys as number[]}
            status="active"
            afterUpdate={() => {
              action.current?.reload();
              action.current?.clearSelected?.();
            }}
          />
        </>
      ),

      <Dropdown
        key={"more"}
        placement={"bottom"}
        trigger={["click"]}
        menu={{ items }}
      >
        <Button type={"primary"} icon={<EllipsisOutlined />} />
      </Dropdown>,
      member.permissions.includes("course:create") && (
        <CourseModalForm
          key={"create"}
          rest={{
            type: "create",
            onAfterCreate: (record) => {
              action.current?.reload();
              rest?.onAfterCreate?.(record);
            },
          }}
        />
      ),
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {
      onChange: (selectedRowKeys) => {
        setSomeSelected(selectedRowKeys.length > 0);
      },
    },
    options: false,
    pagination: { showSizeChanger: true },
    toolBarRender,

    request,
    actionRef: action,
    scroll: {
      y: parentHeight + (total ? 0 : 40) - (someSelected ? 225 : 165),
      x: 800,
    },
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Cтатус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: Object.fromEntries(
          Object.entries(statusValueEnum).filter(
            ([key]) => !["to_delete", "deleting", "deleted"].includes(key),
          ),
        ),
        renderFormItem(_, config) {
          return (
            <ProFormSelect
              {...config}
              fieldProps={{ tagRender }}
              mode="multiple"
            />
          );
        },
      },
      {
        title: () => "Идентификатор",
        formItemProps: {
          tooltip: false,
        },
        tooltip: true,
        dataIndex: "key",
        hideInTable: !features.isEnabled("courses_keys"),
        hideInSearch: !features.isEnabled("courses_keys"),
      },
      {
        title: "Описание",
        dataIndex: "description",
        hideInSearch: true,
      },
      {
        title: () => "Время создания",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        sorter: true,
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "created_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <DateTimeRangePickerWithTimeControls
              {...config}
              name={["range", "created_at"]}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  return (
    <>
      {courseImportModalHolder}
      {registryImportModalHolder}
      <div ref={ref} style={{ height: "100%", width: "100%" }}>
        <ProTable<Data, Params>
          {...deepmergeProTableProps(defaultProps as any, props as any)}
        />
      </div>
    </>
  );
};

export default CoursesTable;
