import React from "react";
import {
  ProForm,
  ProFormBaseGroupProps,
  ProFormDigit,
  ProFormText,
} from "@ant-design/pro-components";

type GenerateDocumentsFormGroupProps = ProFormBaseGroupProps & {
  isRequiredLabels: boolean;
};

const GenerateDocumentsFormGroup: React.FC<GenerateDocumentsFormGroupProps> = ({
  isRequiredLabels = true,
  ...props
}) => {
  return (
    <ProForm.Group {...props}>
      <ProFormText
        label={"Наименование программы обучения"}
        name={"training_program_name"}
        rules={[{ required: isRequiredLabels, max: 255 }]}
      />
      <ProFormDigit
        label="Количество часов"
        name="hours_count"
        rules={[{ required: isRequiredLabels }]}
      />
      <ProFormText
        label={"Код программы в реестре"}
        name={"registry_program_code"}
        rules={[{ required: isRequiredLabels, max: 255 }]}
      />
    </ProForm.Group>
  );
};
export default GenerateDocumentsFormGroup;
export type { GenerateDocumentsFormGroupProps };
