import React from "react";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Tag, TagProps } from "antd";

export const tagRender: (props: CustomTagProps) => React.ReactElement = ({
  label,
  closable,
  onClose,
}) => (
  <>
    {React.isValidElement(label) && label.type === Tag ? (
      React.cloneElement(label as React.ReactElement<TagProps>, {
        closable,
        onClose,
        style: { marginBlock: "2px", marginInlineEnd: "4px" },
      })
    ) : (
      <Tag closable={closable} onClose={onClose}>
        {label}
      </Tag>
    )}
  </>
);
