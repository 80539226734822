import React from "react";
import axios from "@/axios";
import { Exam, Test, TestParticipant } from "@/models";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
} from "@/shared/types/orion-rest";
import useSWR from "swr";
import { message, Spin, theme } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "@/shared/ant-design/button/ui/button";

const examStatusButtonTextEnum: { [key in Exam["status"]]?: string } = {
  launched: "Продолжить",
  completed: "Сдан",
};

type ExamButtonProps = {
  poolParticipantId?: string;
  testId: Test["id"];
};

const ExamButton: React.FC<ExamButtonProps> = ({
  poolParticipantId,
  testId,
}) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { data: testParticipant, ...testParticipantsRequest } = useSWR(
    [`/api/test-participants/search`, poolParticipantId, testId],
    async ([url, pool_participant_id, test_id]) => {
      return axios
        .post<OrionRestIndexResponse<TestParticipant>>(
          url,
          {
            filters: [
              {
                field: "pool_participant_id",
                value: Number(pool_participant_id),
              },
              { field: "test_id", value: test_id },
            ],
          },
          {
            params: { limit: 1, page: 1 },
          },
        )
        .then((response) => response.data.data[0] ?? null);
    },
  );

  const { data: exam, ...examsRequest } = useSWR(
    [`/api/exams/search`, poolParticipantId, testId, "button"],
    async ([url, pool_participant_id, test_id]) => {
      return axios
        .post<OrionRestIndexResponse<Exam>>(
          url,
          {
            filters: [
              { field: "pool_participant_id", value: pool_participant_id },
              { field: "test_participant.test_id", value: test_id },
            ],
            sort: [{ field: "attempt_number", direction: "desc" }],
          },
          { params: { limit: 1, page: 1 } },
        )
        .then((response) => response.data.data[0] ?? null);
    },
    { refreshInterval: 1000 },
  );

  if (examsRequest.error) throw examsRequest.error;
  if (testParticipantsRequest.error) throw testParticipantsRequest.error;
  if (examsRequest.isLoading || testParticipantsRequest.isLoading) {
    return <Spin />;
  }

  const onExamStart = async () => {
    let examId = null;
    if (exam && exam.status === "launched") {
      examId = exam.id;
    } else {
      await axios
        .post<OrionRestCreateResponse<Exam>>(`/api/exams`, {
          test_participant_id: testParticipant!.id,
          pool_participant_id: poolParticipantId,
          status: "launched",
        })
        .then((res) => (examId = res.data.data.id))
        .catch((error) => {
          message.error(
            error.response.data.message ?? "Ошибка создания экзамена",
          );
        });
    }

    if (examId) {
      const { pathname, search } = window.location;

      navigate(
        `/education/${poolParticipantId}/exams/${examId}?pool_participant_url=${pathname}${search}`,
      );
    }
  };

  const isExamInChecking =
    exam && (exam.status === "to_check" || exam.status == "checking");

  const hasExceededAttemptLimit =
    exam &&
    exam.status !== "launched" &&
    testParticipant?.is_limits_testing_attempts &&
    exam.attempt_number >= testParticipant?.testing_attempts_limit!;

  const isCreatesExamsAfterComplete =
    testParticipant?.is_creates_exams_after_complete ?? false;

  if (
    !testParticipant ||
    isExamInChecking ||
    (!isCreatesExamsAfterComplete && hasExceededAttemptLimit)
  ) {
    return null;
  }

  return (
    <Button
      onClick={onExamStart}
      type={"primary"}
      block
      disabled={
        !isCreatesExamsAfterComplete ? exam?.status === "completed" : false
      }
      style={{
        background:
          exam?.status === "completed" && !isCreatesExamsAfterComplete
            ? token.blue1
            : token.colorPrimary,
      }}
    >
      {exam?.status === "completed" && isCreatesExamsAfterComplete
        ? "Начать"
        : (exam && examStatusButtonTextEnum[exam.status]) ?? "Начать"}
    </Button>
  );
};

export default ExamButton;
