import React, { useState } from "react";
import { Longread } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Flex, message, Modal, Spin, theme } from "antd";
import { ButtonProps } from "antd/es/button";
import { RestProps } from "@/shared/rest/lib/types";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import BaseReactQuill from "react-quill";
import styled from "styled-components";
import { GlobalToken } from "antd/es/theme/interface";
import "react-quill/dist/quill.snow.css";
import useMe from "@/entities/me/lib/use";

const SpinWrapper = styled.div`
  height: 100%;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
`;

type FormData = Longread & {
  content: string | null;
};

type Props = ProFormProps<FormData> & {
  rest: RestProps<Longread>;
  onDelete?: () => void;
};

type ReactQuillProps = React.ComponentProps<typeof BaseReactQuill>;
const ReactQuill = styled(BaseReactQuill)<
  ReactQuillProps & { token: GlobalToken }
>`
  height: 100%;
  word-break: break-word;

  background: ${({ token }) => token.colorWhite};
  border-radius: ${({ token }) => `${token.borderRadius}px`};
  color: rgba(0, 0, 0, 0.88) !important;

  .ql-toolbar {
    // left top + right top
    border-radius: ${({ token }) =>
      `${token.borderRadius}px ${token.borderRadius}px 0 0`};
  }

  .ql-container {
    // left buttom + right buttom
    border-radius: ${({ token }) =>
      `0 0 ${token.borderRadius}px ${token.borderRadius}px`};
    height: calc(100% - 42px);
  }
  @media screen and (max-width: 1224px) {
    .ql-container {
      height: calc(100% - 66px);
    }
  }
`;

const LongreadForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [error, setError] = useState<Error>();
  const { token } = theme.useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [form] = ProForm.useForm<FormData>();
  const content = ProForm.useWatch("content", form);
  const [modal, modalHolder] = Modal.useModal();
  const member = useMe();

  const defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      setIsLoading(true);
      return axios
        .get<OrionRestShowResponse<Longread>>(
          `/api/longreads/${rest.recordKey}`,
          { params: { include: "content_file" } },
        )
        .then((res) => res.data.data)
        .then((longread) => {
          if (!longread.content_file) {
            return { ...longread, content: null };
          }

          return axios.get(longread.content_file!.url!).then((res) => {
            return { ...longread, content: res.data };
          });
        })
        .catch((err) => {
          setError(err);
          throw err;
        })
        .finally(() => setIsLoading(false));
    };

    defaultProps.onFinish = async (values) => {
      setIsLoading(true);
      setIsSubmitButtonLoading(true);

      const { content, ...restValues } = values;

      return axios
        .put<OrionRestUpdateResponse<Longread>>(
          `/api/longreads/${rest.recordKey}`,
          restValues,
        )
        .then(async (res) => res.data.data)
        .then(async (longread) => {
          if (content) {
            const name = `${longread.uuid}.longread`;
            await axios
              .post(`/api/filesystem/s3`, { name })
              .then((res) => res.data.data)
              .then(async (data) => {
                await axios.put(data.url, content);
                return data.key;
              })
              .then(async (key) => {
                return await axios
                  .post(`/api/files`, {
                    name,
                    type: "longread",
                    key,
                  })
                  .then((res) => res.data.data)
                  .then((file) => {
                    return axios.put<OrionRestUpdateResponse<Longread>>(
                      `/api/longreads/${values.id}`,
                      { content_file_id: file.id },
                    );
                  });
              });
          }

          return longread;
        })
        .then(async (longread) => {
          rest.onAfterUpdate?.(longread);
          message.success("Лонгрид успешно обновлен");
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? `Ошибка при обновлении лонгрида`,
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsLoading(false);
          setIsSubmitButtonLoading(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete: ButtonProps["onClick"] = () => {
    modal.warning({
      title: "Удалить текущий лонгрид?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await axios.delete(`/api/longreads/${props.id}`);
        await onDeleteProp?.();
        message.success("Лонгрид успешно удалён");
      },
    });
  };

  if (error) throw error;

  return (
    <SpinWrapper>
      <Spin style={{ height: "100%" }} spinning={isLoading}>
        {modalHolder}
        <ProForm<FormData> {...props} style={{ height: "100%" }}>
          <Flex vertical style={{ width: "100%", height: "100%" }}>
            <ProFormDigit name="id" label="ID" hidden />
            <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
              {member.permissions.includes("course:update") && (
                <>
                  <Button danger type={"primary"} onClick={onDelete}>
                    Удалить
                  </Button>
                  <Button
                    loading={isSubmitButtonLoading}
                    type={"primary"}
                    onClick={form.submit}
                  >
                    Сохранить
                  </Button>
                </>
              )}
            </Flex>
            <ProFormDigit name="course_id" label="ID курса" hidden />
            <ProFormText
              name="name"
              label="Название"
              rules={[{ required: true, max: 255 }]}
            />
            <ProFormText name="content" hidden />
            <div
              data-text-editor="quill_wrapper"
              style={{ height: "calc(100% - 96px - 22px)" }}
            >
              <ReactQuill
                token={token}
                theme={"snow"}
                bounds={`[data-text-editor="quill_wrapper"]`}
                value={content ? content : undefined}
                onChange={(value) => form.setFieldsValue({ content: value })}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ align: [] }],
                    [{ color: [] }, { background: [] }],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video", "formula"],
                    ["code-block"],
                    ["clean"],
                  ],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
              />
            </div>
          </Flex>
        </ProForm>
      </Spin>
    </SpinWrapper>
  );
};

export default LongreadForm;
