import React, { useCallback } from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormGroup,
  ProFormList,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { theme, Typography } from "antd";
import DateTimePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-picker-with-time-controls/ui/date-time-picker-with-time-controls";
import MemberSelect from "@/entities/member/ui/select";
import MemberPositionSelect from "@/entities/member/ui/member-position-select";
import { useTranslation } from "react-i18next";
import { Commission } from "../lib/model";
import { CommissionMember } from "@/models";
import useMe from "@/entities/me/lib/use";
import {
  MAX_HEAD_COMMISSION_MEMBERS_COUNT,
  MAX_MEMBER_COMMISSION_MEMBERS_COUNT,
  MIN_HEAD_COMMISSION_MEMBERS_COUNT,
  MIN_MEMBER_COMMISSION_MEMBERS_COUNT,
} from "../lib/constants";

const CommissionFormFields: React.FC<{
  setDeleteMembers?: (id: CommissionMember["id"]) => void;
  type: "create" | "update";
}> = ({ setDeleteMembers, type }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const member = useMe();

  const form = ProForm.useFormInstance<Commission>();
  const members = ProForm.useWatch("members", form);
  const membersHead = ProForm.useWatch("members_head", form);

  const autoSelectSingleOption = useCallback(
    async (
      memberId: CommissionMember["member_id"],
      index: number,
      type: "members" | "members_head",
    ) => {
      await axios
        .post("/api/member-positions/search", {
          filters: [
            {
              field: "member_id",
              operator: "=",
              value: memberId,
            },
          ],
        })
        .then((res) => {
          if (res.data.data.length === 1) {
            form.setFieldValue(
              [type, index, "position_id"],
              res.data.data[0].position_id,
            );
          } else {
            form.setFieldValue([type, index, "position_id"], null);
          }
        });
    },
    [form],
  );

  return (
    <>
      <ProFormText
        name="name"
        label="Название"
        colProps={{ span: 24 }}
        rules={[{ required: true }, { max: 255 }]}
      />
      <ProFormGroup
        title="Учредительный документ"
        titleStyle={{ marginBottom: token.marginXS }}
      >
        <ProFormText
          name="establishment_document_name"
          label="Название"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 10 }}
        />
        <ProFormText
          name="establishment_document_number"
          label="Номер"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 10 }}
        />
        <DateTimePickerWithTimeControls
          name="establishment_document_date"
          label="Дата"
          rules={[{ required: true }]}
          colProps={{ span: 4 }}
          fieldProps={{ showNow: false }}
        />
      </ProFormGroup>
      <ProFormGroup
        title="Организация"
        titleStyle={{ marginBottom: token.marginXS }}
      >
        <ProFormText
          name="company_name"
          label="Наименование"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 12 }}
        />
        <ProFormText
          name="company_inn"
          label="ИНН организации"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 12 }}
        />
      </ProFormGroup>
      <ProFormGroup
        title="Руководитель организации"
        titleStyle={{ marginBottom: token.marginXS }}
      >
        <ProFormText
          name="company_head_full_name"
          label="ФИО"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 12 }}
        />
        <ProFormText
          name="company_head_position"
          label="Должность"
          rules={[{ required: true }, { max: 255 }]}
          colProps={{ span: 12 }}
        />
      </ProFormGroup>
      <ProFormList
        name={"members_head"}
        max={MIN_HEAD_COMMISSION_MEMBERS_COUNT}
        min={MAX_HEAD_COMMISSION_MEMBERS_COUNT}
        creatorButtonProps={{
          creatorButtonText: "Добавить еще",
          type: "primary",
          block: false,
          icon: false,
        }}
        label={<Typography.Text strong>Председатель</Typography.Text>}
        copyIconProps={false}
        deleteIconProps={{ tooltipText: "Удалить" }}
        onAfterRemove={async (index: number | number[]) => {
          if (
            membersHead &&
            typeof index === "number" &&
            membersHead[index].id
          ) {
            setDeleteMembers?.(membersHead[index].id);
          }
        }}
        rules={[
          {
            validator: (_, value) => {
              if (value.length !== 1) {
                return Promise.reject(
                  "Минимальное количество председателей - 1",
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(_, index, action) => {
          const memberId = action.getCurrentRowData()?.member_id;
          const id = action.getCurrentRowData()?.id;

          return (
            <ProFormGroup>
              <ProFormDigit hidden name={"commission_id"} />
              <ProFormDigit name={"id"} hidden />
              <ProFormSelect
                hidden
                name={"type"}
                valueEnum={{ head: "Председатель" }}
              />
              <MemberSelect
                name={"member_id"}
                label="Пользователь"
                colProps={{ span: 12 }}
                rules={[{ required: true }]}
                onChange={async (value) => {
                  if (value) {
                    await autoSelectSingleOption(value, index, "members_head");
                    if (id) {
                      setDeleteMembers?.(id);
                      action.setCurrentRowData({
                        id: null,
                      });
                    }
                  } else {
                    action.setCurrentRowData({
                      position_id: null,
                    });
                  }
                }}
              />
              <MemberPositionSelect
                name="position_id"
                disabled={!memberId}
                memberId={memberId}
                listName={["members_head", index, "position_id"]}
                label={t("Должность")}
                rules={[{ required: true }]}
                colProps={{ span: 12 }}
                fieldProps={{
                  onChange: async (value) => {
                    if (value) {
                      if (id) {
                        setDeleteMembers?.(id);
                        action.setCurrentRowData({
                          id: null,
                        });
                      }
                    }
                  },
                }}
              />
            </ProFormGroup>
          );
        }}
      </ProFormList>
      <ProFormList
        name={"members"}
        max={MAX_MEMBER_COMMISSION_MEMBERS_COUNT}
        min={MIN_MEMBER_COMMISSION_MEMBERS_COUNT}
        creatorButtonProps={{
          creatorButtonText: "Добавить еще",
          type: "primary",
          block: false,
          icon: false,
        }}
        rules={[
          {
            validator: (_, value) => {
              if (value.length < 2) {
                return Promise.reject("Минимальное количество участников 2");
              }
              return Promise.resolve();
            },
          },
        ]}
        label={<Typography.Text strong>Участники</Typography.Text>}
        copyIconProps={false}
        deleteIconProps={
          member.permissions.includes("commission:update") && {
            tooltipText: "Удалить",
          }
        }
        onAfterRemove={async (index: number | number[]) => {
          if (members && typeof index === "number" && members[index].id) {
            setDeleteMembers?.(members[index].id);
          }
        }}
      >
        {(_, index, action) => {
          const memberId = action.getCurrentRowData()?.member_id;
          const id = action.getCurrentRowData()?.id;

          return (
            <ProFormGroup>
              <ProFormDigit hidden name={"commission_id"} />
              <ProFormDigit name={"id"} hidden />
              <ProFormSelect
                hidden
                name={"type"}
                valueEnum={{ member: "Участник" }}
              />
              <MemberSelect
                name={"member_id"}
                label="Пользователь"
                colProps={{ span: 12 }}
                rules={[{ required: true }]}
                onChange={async (value) => {
                  if (value) {
                    await autoSelectSingleOption(value, index, "members");
                    if (id) {
                      setDeleteMembers?.(id);
                      action.setCurrentRowData({
                        id: null,
                      });
                    }
                  } else {
                    action.setCurrentRowData({
                      position_id: null,
                    });
                  }
                }}
              />
              <MemberPositionSelect
                name="position_id"
                listName={["members", index, "position_id"]}
                disabled={!memberId}
                memberId={memberId}
                label={t("Должность")}
                rules={[{ required: true }]}
                colProps={{ span: 12 }}
                fieldProps={{
                  onChange: async (value) => {
                    if (value) {
                      if (id) {
                        setDeleteMembers?.(id);
                        action.setCurrentRowData({
                          id: null,
                        });
                      }
                    }
                  },
                }}
              />
            </ProFormGroup>
          );
        }}
      </ProFormList>
    </>
  );
};

export default CommissionFormFields;
