import axios from "@/axios";
import {
  ProForm,
  ProFormCheckbox,
  ProFormList as AntdProFormList,
  ProFormRadio,
  ProFormSelect as AntdProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import {
  InfoCircleOutlined,
  CaretDownOutlined,
  CaretUpFilled,
} from "@ant-design/icons";
import { debounce } from "lodash";
import {
  Space as AntdSpace,
  Button,
  Col,
  Flex,
  Row,
  theme,
  Tooltip,
  Typography,
} from "antd";
import styled from "styled-components";
import { Exam } from "@/entities/exam/lib/model";
import { useCallback, useEffect, useState } from "react";

const Space = styled(AntdSpace)`
  .ant-space .column-content {
    width: 100%;
    overflow: auto;
    height: 100%;
  }

  @media (max-width: 992px) {
    .ant-space .column-content {
      height: 100%;
    }
  }
`;

const ProFormList = styled(AntdProFormList)`
  .ant-pro-form-list-container {
    width: 100%;
  }
  .ant-pro-form-list-item {
    align-items: flex-start !important;
  }
`;

const ProFormSelect = styled(AntdProFormSelect)`
  .ant-select-selection-item {
    white-space: wrap;
    line-height: inherit !important;
  }

  height: auto;
`;

type Props = {
  question: Exam["reply"][number];
  questionIndex: number;
  exam: Exam;
};

const Question: React.FC<Props> = ({ question, questionIndex, exam }) => {
  const form = ProForm.useFormInstance<Exam>();
  ProForm.useWatch(["reply", questionIndex], form);

  const { token } = theme.useToken();
  const [selectedMatchableUuid, setSelectedMatchableUuid] = useState<
    (string | undefined)[]
  >(() => {
    if (question.type === "matching") {
      const exam: Exam = form.getFieldsValue(true);
      const question = exam.reply[questionIndex];
      const selectedMatchableUuid = question.answers!.map(
        (answer) => answer.matchable_uuid,
      );
      return selectedMatchableUuid;
    }
    return [];
  });

  useEffect(() => {
    if (question.type === "sequence") {
      question.is_touched = true;
      const exam: Exam = form.getFieldsValue(true);
      exam.reply[questionIndex] = question;
      onChangeQuestion(exam.reply);
    }
  }, [question.type]);

  const Component = question.type === "single" ? ProFormRadio : ProFormCheckbox;

  const onChangeQuestion = useCallback(
    debounce(async (reply) => {
      await axios.put(`/api/exams/${exam.id}`, {
        reply,
        pool_participant_id: exam!.pool_participant_id,
        test_participant_id: exam!.test_participant_id,
      });
    }, 500),
    [exam],
  );

  const onQuestionChange = async (answerIndex?: number) => {
    const exam: Exam = form.getFieldsValue(true);
    const reply = exam.reply.map((answer) => {
      if (
        answer.type === "matching" &&
        answer.answers?.some((answer) => !answer.matchable_uuid)
      ) {
        return {
          ...answer,
          answers: [],
        };
      }
      return answer;
    });

    onChangeQuestion(reply);
  };

  return (
    <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        {question.text}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        * {question.type === "single" && "Выберите один ответ"}
        {question.type === "multiple" && "Выберите несколько ответов"}
        {question.type === "short_answer" && "Введите ответ в поле ниже"}
        {question.type === "matching" && (
          <>
            Выберите соответствующий ответ из выпадающего списка{" "}
            <Tooltip
              title={
                "Каждый вариант ответа может быть выбран только один раз и не повторяется"
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </>
        )}
        {question.type === "sequence" &&
          "Расположите ответы в правильном порядке"}
      </Typography.Paragraph>
      <Space direction="vertical" style={{ width: "100%" }}>
        {question.type === "short_answer" && (
          <ProFormText
            formItemProps={{ style: { marginBottom: 0 } }}
            name={["reply", questionIndex, "answer"]}
            fieldProps={{
              onChange: () => {
                onQuestionChange();
              },
            }}
          />
        )}
        {(question.type === "single" || question.type === "multiple") &&
          question.answers?.map((answer, answerIndex) => {
            return (
              <Component
                formItemProps={{ style: { marginBottom: 0 } }}
                key={answerIndex}
                name={[
                  "reply",
                  questionIndex,
                  "answers",
                  answerIndex,
                  "is_correct",
                ]}
                fieldProps={{
                  onChange: () => {
                    const exam: Exam = form.getFieldsValue(true);
                    const question = exam.reply[questionIndex];
                    if (question.type === "single") {
                      question.answers = question.answers?.map(
                        (answer, index) => {
                          console.log(answerIndex);
                          return {
                            ...answer,
                            is_correct: index === answerIndex,
                          };
                        },
                      );
                      exam.reply[questionIndex] = question;
                      form.setFieldsValue(exam);
                    }
                    onQuestionChange(answerIndex);
                  },
                }}
              >
                {answer.value}
              </Component>
            );
          })}

        {(question.type === "matching" || question.type === "sequence") && (
          <ProFormList
            style={{ width: "100%" }}
            copyIconProps={false}
            deleteIconProps={false}
            creatorButtonProps={false}
            name={["reply", questionIndex, "answers"]}
            actionRender={
              question.type === "matching"
                ? undefined
                : (fieldProps, { move }) => {
                    const lastIndex =
                      form.getFieldValue(["reply", questionIndex, "answers"])
                        .length - 1;

                    return [
                      <Button
                        key={"move-down"}
                        icon={<CaretDownOutlined />}
                        type={"text"}
                        disabled={fieldProps.name === lastIndex}
                        onClick={() => {
                          move(fieldProps.name, fieldProps.name + 1);
                          onQuestionChange();
                        }}
                      />,
                      <Button
                        key={"move-up"}
                        icon={<CaretUpFilled />}
                        type={"text"}
                        disabled={fieldProps.name === 0}
                        onClick={() => {
                          move(fieldProps.name, fieldProps.name - 1);
                          onQuestionChange();
                        }}
                      />,
                    ];
                  }
            }
          >
            {(_, index) => {
              return (
                <>
                  {question.type === "matching" && (
                    <Row
                      style={{
                        width: "100%",
                        wordBreak: "break-word",
                      }}
                      gutter={token.paddingSM}
                    >
                      <Col span={12}>
                        <ProFormText name={"matched_value"} readonly />
                      </Col>
                      <Col span={12}>
                        <ProFormSelect
                          rules={[{ required: true }]}
                          name={"matchable_uuid"}
                          options={question.matchable?.map((answer) => ({
                            label: answer.value,
                            value: answer.uuid,
                            disabled: selectedMatchableUuid.includes(
                              answer.uuid,
                            ),
                          }))}
                          fieldProps={{
                            optionRender: (option) => {
                              return (
                                <Typography.Text
                                  disabled={option.data.disabled}
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  {option.label}
                                </Typography.Text>
                              );
                            },
                            onChange: () => {
                              const exam: Exam = form.getFieldsValue(true);
                              const question = exam.reply[questionIndex];
                              const selectedMatchableUuid =
                                question.answers!.map(
                                  (answer) => answer.matchable_uuid,
                                );
                              setSelectedMatchableUuid(selectedMatchableUuid);

                              onQuestionChange();
                            },
                          }}
                        />
                      </Col>
                      <ProFormText hidden name={"matched_uuid"} />
                    </Row>
                  )}
                  {question.type === "sequence" && (
                    <Flex style={{ wordBreak: "break-all" }}>
                      <ProFormText
                        name={"value"}
                        readonly
                        fieldProps={{
                          prefix: `${index + 1}. `,
                        }}
                      />
                      <ProFormText hidden name={"uuid"} />
                    </Flex>
                  )}
                </>
              );
            }}
          </ProFormList>
        )}
      </Space>
    </div>
  );
};

export default Question;
