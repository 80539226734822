import { Exam } from "@/models";
import { Tag } from "antd";
import { ReactNode } from "react";

const examStatusValueEnum: Record<Exam["status"], ReactNode> = {
  launched: <Tag color="processing">В процессе</Tag>,
  to_check: <Tag>На проверке</Tag>,
  checking: <Tag>Проверяется</Tag>,
  completed: <Tag color="success">Успешно</Tag>,
  failed: <Tag color="error">Неудача</Tag>,
};

export default examStatusValueEnum;
