import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Commission } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { commission_id } = useParams();

  const {
    data: commission,
    error,
    isLoading,
  } = useSWR(`/api/commissions/${commission_id!}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Commission>>(url)
      .then((res) => res.data.data);
  });

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!commission) throw new Error("Commission not found");

  return <>{commission.name}</>;
};

export default BreadcrumbItem;
