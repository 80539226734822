import React from "react";
import { Flex, Space, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { useParams } from "react-router-dom";
import CommissionForm from "@/entities/commission/ui/form";

const Page: React.FC = () => {
  const { commission_id } = useParams();
  const { token } = theme.useToken();
  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        style={{
          width: "100%",
          height: "calc(100% - 30px)",
          backgroundColor: token.colorBgContainer,
          padding: token.paddingMD,
          paddingTop: 0,
        }}
        styles={{ item: { height: "100%", width: "100%" } }}
      >
        <CommissionForm rest={{ type: "update", recordKey: commission_id }} />
      </Space>
    </Flex>
  );
};

export default Page;
