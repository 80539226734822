import { IMPERSONATION_MEMBER_ID_KEY } from "@/constants";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const ContextImpersonationCleanUrlParams: React.FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const impersonationMemberId = searchParams.get(IMPERSONATION_MEMBER_ID_KEY);
    if (impersonationMemberId) {
      localStorage.clear();
      localStorage.setItem(IMPERSONATION_MEMBER_ID_KEY, impersonationMemberId);
      searchParams.delete(IMPERSONATION_MEMBER_ID_KEY);
      setSearchParams(searchParams);
    }
  }, []);

  return <>{children}</>;
};

export default ContextImpersonationCleanUrlParams;
