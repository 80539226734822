import * as React from "react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AccessDeniedResult from "@/entities/me/ui/access-denied-result";
import {
  Avatar,
  ConfigProvider,
  Dropdown,
  Flex,
  Grid,
  Image,
  Layout as AntLayout,
  Menu,
  Spin,
  theme,
  Typography,
} from "antd";
import useMe from "@/entities/me/lib/use";
import { useAuth } from "react-oidc-context";
import {
  BookOutlined,
  BugOutlined,
  BulbOutlined,
  ControlOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AvatarWithLoader from "@/shared/ant-design/avatar/ui/avatar-with-loader";
import useTheme from "@/shared/theme/lib/use.tsx";
import logo from "../../../public/logo.svg";
import useSentryUserFeedbackModal from "@/shared/sentry/lib/use-user-feedback-modal.tsx";
import useSWR from "swr";
import axios from "@/axios";
import NotificationDrawer from "@/entities/notification/ui/dropdown";
import HeaderContext from "@/entities/layout/lib/header-context";
import useSpace from "@/entities/space/lib/use";
import SupportDropdown from "@/entities/support/ui/dropdown";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import { IMPERSONATION_MEMBER_ID_KEY } from "@/constants";

const { useBreakpoint } = Grid;

let Layout: React.FC = () => {
  const themeAlgorithm = useTheme();
  const member = useMe();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const auth = useAuth();
  const screens = useBreakpoint();
  const space = useSpace();

  const [profileOpen, setProfileOpen] = useState(false);

  const { pathname } = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>([]);

  const [sentryUserFeedbackModal, sentryUserFeedbackModalHolder] =
    useSentryUserFeedbackModal();

  useEffect(() => {
    if (location.pathname.startsWith("/education")) {
      setSelectedMenuItem(["education"]);
    } else if (location.pathname.startsWith("/manage")) {
      setSelectedMenuItem(["manage"]);
    } else {
      setSelectedMenuItem([]);
    }
  }, [pathname]);

  const [hiddenHeader, setHiddenHeader] = useState<boolean>(false);

  if (!member) return <AccessDeniedResult />;

  const { data: docsUrl, ...docsHostRequest } = useSWR(
    "/api/.well-known/docs-host",
    async () => {
      return await axios
        .get("/api/.well-known/docs-host")
        .then((response) => response.data.value)
        .then((value) => `${window.location.protocol}//${value}`);
    },
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  const profileActions: ItemType[] = [
    {
      key: "profile",
      label: "Профиль",
      icon: <UserOutlined />,
      onClick: () => navigate(`/profile`),
    },
    {
      key: "docs",
      label: (
        <a href={docsUrl} target="_blank">
          Документация
        </a>
      ),
      icon: <BookOutlined />,
    },
    {
      key: "feedback",
      label: "Тех. поддержка",
      icon: <BugOutlined />,
      onClick: () => {
        sentryUserFeedbackModal.open();
      },
    },
    {
      key: "logout",
      label: "Выйти",
      danger: true,
      icon: <LogoutOutlined />,
      onClick: async () => {
        if (localStorage.getItem(IMPERSONATION_MEMBER_ID_KEY)) {
          localStorage.removeItem(IMPERSONATION_MEMBER_ID_KEY);
        }
        await auth.removeUser();
        await auth.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        });
      },
    },
  ];

  const menuItems: MenuItemType[] = [
    {
      key: "education",
      label: "Мое обучениe",
      icon: screens.md ? false : <BulbOutlined />,
      onClick: () => navigate(`/education`),
    },
    ...(member.permissions.length > 0
      ? [
          {
            key: "manage",
            label: "Управление",
            icon: screens.md ? false : <ControlOutlined />,
            onClick: () => navigate(`/manage`),
          },
        ]
      : []),
  ];

  if (docsHostRequest.isLoading) return <Spin />;
  if (docsHostRequest.error) throw docsHostRequest.error;

  return (
    <>
      {sentryUserFeedbackModalHolder}
      <AntLayout style={{ height: "100%" }}>
        <AntLayout.Header
          hidden={hiddenHeader}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: "64px",
            width: "100%",
            paddingLeft: token.paddingMD,
            paddingRight: token.paddingMD,
            borderBottom: `1px solid ${token.colorSplit}`,
          }}
        >
          <Flex
            justify={"space-between"}
            style={{ maxWidth: "1920px", margin: "0 auto" }}
          >
            <Flex align="center">
              <Image
                src={logo}
                preview={false}
                style={{ width: "32px", height: "32px" }}
              />
              <Typography.Text
                strong
                style={{
                  paddingLeft: token.paddingXS,
                  color:
                    themeAlgorithm.value === "light"
                      ? "#145082"
                      : token.colorWhite,
                }}
              >
                СИНИЦА
              </Typography.Text>
              {screens.md && (
                <ConfigProvider
                  theme={{
                    components: {
                      Menu: {
                        colorBgContainer: token.Layout?.headerBg,
                      },
                    },
                  }}
                >
                  <Menu
                    theme="light"
                    mode="horizontal"
                    items={menuItems}
                    selectedKeys={selectedMenuItem}
                    disabledOverflow
                  />
                </ConfigProvider>
              )}
            </Flex>
            <Flex gap={token.padding}>
              <Flex align="center">
                <SupportDropdown placement="bottomRight" isReverseItems />
              </Flex>

              <Flex align="center">
                <NotificationDrawer />
              </Flex>

              <Dropdown
                menu={{
                  items: screens.md
                    ? profileActions
                    : [...menuItems, ...profileActions],
                }}
                trigger={["click"]}
                overlayStyle={{ width: "200px" }}
                open={profileOpen}
                onOpenChange={(open) => {
                  setProfileOpen(open);
                }}
              >
                <Flex align="center">
                  {member.avatar_image_file ? (
                    <AvatarWithLoader src={member.avatar_image_file.url!} />
                  ) : (
                    <Avatar
                      size={"large"}
                    >{`${member.last_name[0]}${member.first_name[0]}`}</Avatar>
                  )}
                </Flex>
              </Dropdown>
            </Flex>
          </Flex>
        </AntLayout.Header>

        <AntLayout.Content
          style={{
            padding: token.padding,
            width: "100%",
            maxWidth: "1920px",
            margin: "0 auto",
            height: "100%",
            position: "relative",
          }}
        >
          <HeaderContext.Provider
            value={{ hidden: hiddenHeader, setHidden: setHiddenHeader }}
          >
            <Outlet />
          </HeaderContext.Provider>
        </AntLayout.Content>
      </AntLayout>
    </>
  );
};

export default Layout;
